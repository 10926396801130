// use this utility file for your font stack, buttons, and other miscelaneous things, look through the file to see what is available
// DO NOT EDIT THE UTILITY FILE
@import '/Volumes/l/Design Group/500 Web Marketing/Design and Front-end/front-end/global-scss/utility';


// this image-path variable is used in the bg-img mixin in the utility file
$image-path: '//cdnus.melaleuca.com/Images/Footer/';
 
// Fon-Icon
%icon {
  font: normal normal normal 14px/1 FontAwesome; 
}

.footer-container {
  // Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fbfaf9+0,e9e4dd+50 
  background: #fbfaf9; // Old browsers 
  background: -ms-linear-gradient(top, #fbfaf9 0%, #e9e4dd 50%); // IE10+ 
  background: linear-gradient(to bottom, #fbfaf9 0%, #e9e4dd 50%); // W3C 
  border-radius: 8px;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fbfaf9', endColorstr='#e9e4dd', GradientType=0); // IE6-9 
  float: right;
  font-family: $gotham-n;
  font-size: 11.5px;
  margin: 0;
  padding: 0;
  position: relative;
  width: 913px;

  .skinny & {
    width: 694px;
  }
}

.footer-container a { 
  color: #595959;
  text-decoration: none;
}

.footer-container a:hover {
  color: #595959;
  text-decoration: underline;
}

.footer-container-top {
  float: left;
  margin: 0;
  padding: 12px 23px 0;
  position: relative;
  width: 867px;

  .skinny & {
    margin-bottom: 10px;
    width: 648px;
  }
}

.footer-container-top select {
  color: #75716b;
  width: 190px;
}

.footer-list {
  float: left;
  margin-right: 70px;
  padding: 0;
  position: relative;

  .skinny & {
    margin-right: 56px;
  }

  &.last-list {
    margin-right: 0;
  }

  .footer-list-item {
    display: block;
    margin: 0 0 4px;
  }
}


// spanish styles
.espanol {

  .footer-list {
    margin-right: 45px;

    .skinny & {
      margin-right: 28px;
    }
  }

  &.public-footer {

    .footer-list {
      margin-right:75px;

      .skinny & {
        margin-right: 95px;
      }
    }

    .footer-container-language {

      .skinny & {
        margin-right: 13px;
      }
    }
  }
}


//french styles
.french {

  .footer-list {
    margin-right: 20px;

    .skinny & {
      margin-right: 30px;
    }
  }

  .footer-container-language {

    .skinny & {
      margin-top: 0;
    }
  }

  &.public-footer {

    .footer-list {
      margin-right: 90px;

      .skinny & {
        margin-right: 75px;
      }
    } 
    .footer-container-language {

      .skinny & {
        margin-top: 20px;
      }
    }
  }
}

.footer-container-language {
  float: right;

  .skinny & {
    display: inline-block;
    float: right;
    margin-top: 20px;
  }
}

.select-language {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 3px;
  text-transform: uppercase;
}

// Social Icons
.social-container {
  @include bg-img('social-bg-footer.png');
  background-repeat: repeat;
  clear: both;
  display: inline-block;
  margin: 10px 0 20px;
  padding: 15px 0;
  text-align: center;
  width: 100%;
}

.social-wrapper {
  display: inline-block;
}

.social-header {
  display: inline-block;
  float: left;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  margin-right: 20px;
  padding-top: 11px;
  text-transform: uppercase;
}

.footer-list-social {
  border-right: 1px solid #75716b;
  display: inline-block;
  float: left;
  line-height: 1;
  margin-right: 20px;
  padding-right: 20px;
  text-align: center;

  .footer-list-item {
    display: inline-block;
    float: left;
    margin: 0 5px;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .footer-list-action {  
    background-color: #fff;
    border: 1px solid #595959;
    border-radius: 50%;
    display: inline-block;
    height: 30px;
    width: 30px;

    &:hover {
      text-decoration: none;
    }

    &:after {
      background-color: #fff;
      border-radius: 50%;
      font-size: 19px;
      position: relative;
      top: 6px;
    }
  }
}

.youtube {

  .footer-list-action {

    &:after {
      @extend %icon;
      content: '\f167';
    }
  }
}

  
.facebook {

  .footer-list-action {

    &:after {
      @extend %icon;
      content: '\f09a'; 
    }
  }
}
  
.twitter {

  .footer-list-action {

    &:after {
      @extend %icon;
      content: '\f099';
    }
  }
}
  
.pintrest {

  .footer-list-action {

    &:after {
      @extend %icon;
      content: '\f0d2';
    }
  }
}
  
.instagram {

  .footer-list-action {

    &:after {
      @extend %icon;
      content: '\f16d';
    }
  }
}
  
.google {

  .footer-list-action {

    &:after {
      @extend %icon;
      content: '\f0d5';
    }
  }
}


.footer-container-bottom {
  float: left;
  margin: 0;
  padding: 0 23px 12px;
  position: relative;
  text-align: center;
  width: 867px;

  .skinny & {
    width: auto;
  }
}

.footer-container-bottom em {
  color: #75716b;
  font-style: italic;
  margin: 12px 0 0;
  text-align: center;
}

.footer-list-secondary {
  color: #75716b;
  display: inline-block;
  margin: 3px 0 0;
  text-align: center;

  .skinny & {
    display: block;
  }

  .espanol & {
    display: block;
  }

  .french & {
    display: block;
  }

  .footer-list-secondary-item {
    display: inline-block;

    &:nth-child(2) a {
      border: 1px solid #75716b;
      border-width: 0 1px;
      margin: 0 10px;
      padding: 0 10px;
    }
  }
}



