//***********************//
// THIS IS A SHARED FILE //
// DO NOT EDIT //
//***********************//

//============================//
//============================//
// variables
//============================//
//============================//

// variable for image paths
$image-path: '';

// ----
// variables for typography
// ----
// these are all the fonts we currently have from typography.com
// -ss represents screen smart fonts and should be used for sizes
// 18px or under. Anything larger than that should ideally use non
// screen smart fonts, usually headings.
$archer: 'Archer A', 'Archer B', Rockwell, "Courier Bold", Courier, Georgia, Times, "Times New Roman", serif;  // archer display
$archer-sb: 'Archer 7r', 'Archer A', 'Archer B', Rockwell, "Courier Bold", Courier, Georgia, Times, "Times New Roman", serif; // archer semibold font-weight: 700
$archer-sbi: 'Archer 7i', 'Archer A', 'Archer B', Rockwell, "Courier Bold", Courier, Georgia, Times, "Times New Roman", serif; // archer semibod italic font-weight: 700
$archer-ss: '';  // archer screen smart

$cyclone: 'Cyclone A', 'Cyclone B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; // cyclone 
$cyclone-bg: 'Cyclone Background A', 'Cyclone Background B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;  // cyclone background display
$cyclone-il: 'Cyclone Inline A', 'Cyclone Inline B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; // cyclone inline display

$didot-06: 'Didot 06 A', 'Didot 06 B', "Didot LT STD", "Hoefler Text", Garamond, "Times New Roman", serif; // didot-06 display
$didot-06-mi: 'Didot 06 5i', 'Didot 06 A', 'Didot 06 B', "Didot LT STD", "Hoefler Text", Garamond, "Times New Roman", serif; // didot-06 medium italic: 500
$didot-06-bi: 'Didot 06 5i', 'Didot 06 A', 'Didot 06 B', "Didot LT STD", "Hoefler Text", Garamond, "Times New Roman", serif; // didot-06 bold italic: 700

$gotham: 'Gotham A', 'Gotham B', "Century Gothic", CenturyGothic, AppleGothic, sans-serif; // gotham display
$gotham-th: 'Gotham 1r', 'Gotham A', 'Gotham B', "Century Gothic", CenturyGothic, AppleGothic, sans-serif; // gotham thin font-weight: 100
$gotham-xl: 'Gotham 2r', 'Gotham A', 'Gotham B', "Century Gothic", CenturyGothic, AppleGothic, sans-serif; // gotham extra light font-weight: 200
$gotham-bk: 'Gotham 4r', 'Gotham A', 'Gotham B', "Century Gothic", CenturyGothic, AppleGothic, sans-serif; // gotham book font-weight: 400
$gotham-ul: 'Gotham 9r', 'Gotham A', 'Gotham B', "Century Gothic", CenturyGothic, AppleGothic, sans-serif; // gotham ultra font-weight: 900

$gotham-c: 'Gotham Cond A', 'Gotham Cond B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; // gotham condensed display

$gotham-n: 'Gotham Narrow A', 'Gotham Narrow B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; // gotham narrow display
$gotham-n-t: ''; // gotham narrow thin display font-weight: 100
$gotham-n-ti: ''; // gotham narrow thin italic font-weight: 100
$gotham-n-xl: 'Gotham Narrow 2r', 'Gotham Narrow A', 'Gotham Narrow B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; // gotham narrow extra light display font-weight: 200
$gotham-n-xli: 'Gotham Narrow 2i', 'Gotham Narrow A', 'Gotham Narrow B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; // gotham narrow extra light italic display font-weight: 200
$gotham-n-l: 'Gotham Narrow SSm 3r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; // gotham narrow light display font-weight: 300
$gotham-n-li: 'Gotham Narrow SSm 3i', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;   // gotham narrow light italic display font-weight: 300
$gotham-n-bk: 'Gotham Narrow 4r', 'Gotham Narrow A', 'Gotham Narrow B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; // gotham narrow book display font-weight: 400
$gotham-n-bki: 'Gotham Narrow 4i', 'Gotham Narrow A', 'Gotham Narrow B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; // gotham narrow book italic display font-weight: 400
$gotham-n-m: 'Gotham Narrow 5r', 'Gotham Narrow A', 'Gotham Narrow B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; // gotham narrow medium display font-weight: 500
$gotham-n-mi: 'Gotham Narrow 5i', 'Gotham Narrow A', 'Gotham Narrow B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; // gotham narrow medium italic display font-weight: 500
$gotham-n-b: ''; // gotham narrow bold display font-weight: 700
$gotham-n-bi: ''; // gotham narrow bold italic display font-weight: 700
$gotham-n-bl: 'Gotham Narrow 8r', 'Gotham Narrow A', 'Gotham Narrow B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; // gotham narrow black display font-weight: 800
$gotham-n-bli: ''; // gotham narrow black italic display font-weight: 800
$gotham-n-ul: ''; // gotham narrow ultra display font-weight: 900
$gotham-n-uli: ''; // gotham narrow ultra italic display font-weight: 900

$gotham-n-ss: 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', Arial, sans-serif; // gotham narrow screen smart
$gotham-n-ss-xl: 'Gotham Narrow SSm 2r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; // gotham narrow screen smart extra light font-weight: 200
$gotham-n-ss-xli: 'Gotham Narrow SSm 2i', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; // gotham narrow screen smart extra light italic font-weight: 200
$gotham-n-ss-l: 'Gotham Narrow SSm 3r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; // gotham narrow screen smart light font-weight: 300
$gotham-n-ss-li: 'Gotham Narrow SSm 3i', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; // gotham narrow screen smart light italic font-weight: 300
$gotham-n-ss-bk: 'Gotham Narrow SSm 4r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; // gotham narrow screen smart book font-weight: 400
$gotham-n-ss-bki: 'Gotham Narrow SSm 4i', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; // gotham narrow screen smart book italic font-weight: 400
$gotham-n-ss-m: 'Gotham Narrow SSm 5r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; // gotham narrow screen smart medium font-weight: 500
$gotham-n-ss-mi: 'Gotham Narrow SSm 5i', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; // gotham narrow screen smart medium italic font-weight: 500
$gotham-n-ss-b:  'Gotham Narrow SSm 7r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; // gotham narrow screen smart bold font-weight: 700
$gotham-n-ss-bi: 'Gotham Narrow SSm 7i', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; // gotham narrow screen smart bold italic font-weight: 700
$gotham-n-ss-bl: 'Gotham Narrow SSm 8r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; // gotham narrow screen smart black font-weight: 800
$gotham-n-ss-bli: ''; // gotham narrow screen smart black italic font-weight: 800

$ideal-sans: ''; // ideal sans display

$vitesse-ss: 'Vitesse SSm A', 'Vitesse SSm B', Rockwell, 'Courier Bold', serif;

// ----
// variables for color
// ----
$pm-green-1: #285e1d; // pm == primary
$pm-green-2: #40922f;
$pm-green-3: #8bc280;
$pm-green-4: #d5efcf;

$ah-blue-1: #254759;  // ah == ad hoc
$pm-blue-1: #35657f;
$pm-blue-2: #7694a2;
$pm-blue-3: #bcc4c7;
$pm-blue-4: #dde1e3;

$pm-orange-1: #a4670c;
$pm-orange-2: #d2a534;
$pm-orange-3: #ead8a7;
$pm-orange-4: #f4ebd3;

$sd-red-1: #5fb4e5;  // sd == secondary
$sd-red-2: #004877;
$sd-red-3: #dac79f;
$sd-red-4: #6a4024;

$sd-brown-1: #6d5a3a;
$sd-brown-2: #b8a17b;
$sd-brown-3: #ded4b4;
$sd-brown-4: #faf5ec;

$sd-gray-1: #808080;
$sd-gray-2: #b3b3b3;
$sd-gray-3: #d9d9d9;
$sd-gray-4: #f2f2f2;


//============================//
//============================//
// mixins
//============================//
//============================//

// this mixin is for including background images
@mixin bg-img($img-name, $repeat:no-repeat, $x-pos:left, $y-pos:top, $color:transparent) {
  background: url($image-path + #{$img-name}) $repeat $x-pos $y-pos $color;
}

// these mixins are for initializing the font stack you are using
// from typography.com Use the proper mixin with the font project you
// need then use the font variables at the top of the page in your css

// PROJECT NAME: gotham narrow and cyclone
// -------------------------------------------
// link: <link rel="stylesheet" type="text/css" href="//cloud.typography.com/6443252/773386/css/fonts.css" />
// -------------------------------------------
// fonts & weights: 
// cyclone background: 400
// cyclone inline: 400
// --
// gotham narrow screen smart extra light: 200
// gotham narrow screen smart book: 400
// gotham narrow screen smart medium: 500
// gotham narrow screen smart bold: 700
@mixin gotham-narrow-cyclone() {
  $cyclone-bg: 'Cyclone Background A', 'Cyclone Background B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $cyclone-il: 'Cyclone Inline A', 'Cyclone Inline B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-xl: 'Gotham Narrow SSm 2r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-bk: 'Gotham Narrow SSm 4r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-m: 'Gotham Narrow SSm 5r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-b: 'Gotham Narrow SSm 7r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

// PROJECT NAME: archer gotham narrow
// -------------------------------------------
// link: <link rel="stylesheet" type="text/css" href="//cloud.typography.com/6443252/724346/css/fonts.css" />
// -------------------------------------------
// fonts & weights: 
// archer book: 500
// archer book italic: 500 italic
// archer bold: 800
// archer bold italic: 800 italic
// --
// gotham thin: 100
// gotham ultra: 900
// --
// gotham narrow screen smart extra light: 200
// gotham narrow screen smart medium: 500
// gotham narrow screen smart bold: 700
@mixin archer-gotham-narrow() {
  $archer: 'Archer A', 'Archer B', Rockwell, "Courier Bold", Courier, Georgia, Times, "Times New Roman", serif;
  $gotham-th: 'Gotham 1r', 'Gotham A', 'Gotham B', "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  $gotham-ul: 'Gotham 9r', 'Gotham A', 'Gotham B', "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  $gotham-n-ss-xl: 'Gotham Narrow SSm 2r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-m: 'Gotham Narrow SSm 5r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-b: 'Gotham Narrow SSm 7r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

// PROJECT NAME: welcome - gotham narrow
// -------------------------------------------
// link: <link rel="stylesheet" type="text/css" href="//cloud.typography.com/6443252/770704/css/fonts.css" />
// -------------------------------------------
// fonts & weights: 
// gotham narrow screen smart light: 300
// gotham narrow screen smart book: 400
// gotham narrow screen smart medium: 500
// gotham narrow screen smart black: 800
@mixin welcome-gotham-narrow() {
  $gotham-n-ss-l: 'Gotham Narrow SSm 3r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-bk: 'Gotham Narrow SSm 4r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-m: 'Gotham Narrow SSm 5r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-bl: 'Gotham Narrow SSm 8r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

// PROJECT NAME: gotham narrow ie8 support
// -------------------------------------------
// link: <link rel="stylesheet" type="text/css" href="//cloud.typography.com/6443252/767964/css/fonts.css" />
// -------------------------------------------
// fonts & weights: 
// gotham narrow extra light: 200
// gotham narrow extra light italic: 200
// gotham narrow light: 300
// gotham narrow light italic: 300
// gotham narrow book: 400
// gotham narrow book italic: 400
// gotham narrow black: 800
// --
// gotham narrow screen smart extra light: 200
// gotham narrow screen smart extra light italic: 200
// gotham narrow screen smart light: 300
// gotham narrow screen smart light italic: 300
// gotham narrow screen smart book: 400
// gotham narrow screen smart book italic: 400
// gotham narrow screen smart medium: 500
// gotham narrow screen smart medium italic: 500
// gotham narrow screen smart bold: 700
// gotham narrow screen smart bold italic: 700

@mixin gotham-narrow-ie8() {
  $gotham-n-xl: 'Gotham Narrow 2r', 'Gotham Narrow A', 'Gotham Narrow B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-xli: 'Gotham Narrow 2i', 'Gotham Narrow A', 'Gotham Narrow B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-l: 'Gotham Narrow 3r', 'Gotham Narrow A', 'Gotham Narrow B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-li: 'Gotham Narrow 3i', 'Gotham Narrow A', 'Gotham Narrow B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-bk: 'Gotham Narrow 4r', 'Gotham Narrow A', 'Gotham Narrow B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-bki: 'Gotham Narrow 4i', 'Gotham Narrow A', 'Gotham Narrow B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-bl: 'Gotham Narrow 8r', 'Gotham Narrow A', 'Gotham Narrow B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-xl: 'Gotham Narrow SSm 2r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-xli: 'Gotham Narrow SSm 2i', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-l: 'Gotham Narrow SSm 3r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-li: 'Gotham Narrow SSm 3i', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;  
  $gotham-n-ss-bk: 'Gotham Narrow SSm 4r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-bki: 'Gotham Narrow SSm 4i', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-m: 'Gotham Narrow SSm 5r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-mi: 'Gotham Narrow SSm 5i', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-b: 'Gotham Narrow SSm 7r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-bi: 'Gotham Narrow SSm 7i', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

// PROJECT NAME: Archer-GothamNarrow !!!!!!!!!!! not ie 8 friendly !!!!!!!!!!!
// -------------------------------------------
// link: <link rel="stylesheet" type="text/css" href="//cloud.typography.com/6443252/724346/css/fonts.css" />
// -------------------------------------------
// fonts & weights: 
// archer bold: 800
// --
// gotham narrow light: 300
// gotham narrow light italic: 300
// gotham narrow medium: 500
// gotham narrow medium italic: 500
// --
// gotham narrow screen smart light: 300
// gotham narrow screen smart light italic: 300
// gotham narrow screen smart book: 400
// gotham narrow screen smart book italic: 400
// gotham narrow screen smart medium: 500
// gotham narrow screen smart medium italic: 500

@mixin archer-gothamnarrow() {
  $archer: 'Archer A', 'Archer B', Rockwell, "Courier Bold", Courier, Georgia, Times, "Times New Roman", serif;
  $gotham-n-l: 'Gotham Narrow 3r', 'Gotham Narrow A', 'Gotham Narrow B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-li: 'Gotham Narrow 3i', 'Gotham Narrow A', 'Gotham Narrow B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-m: 'Gotham Narrow 5r', 'Gotham Narrow A', 'Gotham Narrow B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-mi: 'Gotham Narrow 5i', 'Gotham Narrow A', 'Gotham Narrow B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-l: 'Gotham Narrow SSm 3r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-li: 'Gotham Narrow SSm 3i', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;  
  $gotham-n-ss-bk: 'Gotham Narrow SSm 4r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-bki: 'Gotham Narrow SSm 4i', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-m: 'Gotham Narrow SSm 5r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-mi: 'Gotham Narrow SSm 5i', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

// PROJECT NAME: Gotham Condensed/Narrow  !!!!!!!!!!! not ie 8 friendly !!!!!!!!!!!
// -------------------------------------------
// link: <link rel="stylesheet" type="text/css" href="//cloud.typography.com/6443252/664322/css/fonts.css" />
// -------------------------------------------
// fonts & weights: 
// gotham condensed book: 400
// gotham condensed bold: 600
// --
// gotham narrow extra light: 200
// gotham narrow extra light italic: 200
// gotham narrow light: 300
// gotham narrow light italic: 300
// gotham narrow book: 400
// gotham narrow book italic: 400
// gotham narrow medium: 500
// gotham narrow medium italic: 500
// gotham narrow bold: 700
// gotham narrow bold italic: 700

@mixin gotham-condensed-narrow() {
  $gotham-c: 'Gotham Cond A', 'Gotham Cond B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n: 'Gotham Narrow A', 'Gotham Narrow B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

// PROJECT NAME: Affinia haircare  !!!!!!!!!!! not ie 8 friendly !!!!!!!!!!!
// -------------------------------------------
// link: <link rel="stylesheet" type="text/css" href="//cloud.typography.com/6443252/792322/css/fonts.css" />
// -------------------------------------------
// fonts & weights: 
// gotham narrow extra light italic: 200
// --
// gotham narrow screen smart extra light: 200
// gotham narrow screen smart extra light italic: 200
// gotham narrow screen smart medium: 500
// gotham narrow screen smart medium italic: 500

@mixin affinia-haircare() {
  $gotham-n-ss: 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', Arial, sans-serif;
  $gotham-n: 'Gotham Narrow A', 'Gotham Narrow B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

// PROJECT NAME: Eco Sense  !!!!!!!!!!! not ie 8 friendly !!!!!!!!!!!
// -------------------------------------------
// link: <link rel="stylesheet" type="text/css" href="//cloud.typography.com/6443252/644102/css/fonts.css" />
// -------------------------------------------
// fonts & weights: 
// archer book: 500
// archer book italic: 500
// --
// gotham narrow screen smart light: 300
// gotham narrow screen smart light italic: 300
// gotham narrow screen smart medium: 500
// gotham narrow screen smart bold: 700
// gotham narrow screen smart bold italic: 700
// --
// ideal sans extra light italic: 200
// ideal sans bold italic: 700

@mixin eco-sense() {
  $archer: 'Archer A', 'Archer B', Rockwell, "Courier Bold", Courier, Georgia, Times, "Times New Roman", serif;
  $gotham-n-ss: 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', Arial, sans-serif;
  $ideal-sans: 'Ideal Sans A', 'Ideal Sans B', "Gill Sans", "Gill Sans MT", Calibri, sans-serif;
}

// PROJECT NAME: counterAct  !!!!!!!!!!! not ie 8 friendly !!!!!!!!!!!
// -------------------------------------------
// link: <link rel="stylesheet" type="text/css" href="//cloud.typography.com/6443252/764082/css/fonts.css" />
// -------------------------------------------
// fonts & weights: 
// gotham narrow screen smart extra light: 200
// gotham narrow screen smart extra light italic: 200
// gotham narrow screen smart medium: 500
// gotham narrow screen smart medium italic: 500

@mixin counter-act() {
  $gotham-n-ss: 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', Arial, sans-serif;
}

// PROJECT NAME: Simply Fit / gotham narrow  !!!!!!!!!!! not ie 8 friendly !!!!!!!!!!!
// -------------------------------------------
// link: <link rel="stylesheet" type="text/css" href="//cloud.typography.com/6443252/783682/css/fonts.css" />
// -------------------------------------------
// fonts & weights: 
// gotham narrow screen smart extra light: 200
// gotham narrow screen smart light: 300
// gotham narrow screen smart light italic: 300
// gotham narrow screen smart book: 400
// gotham narrow screen smart book italic: 400
// gotham narrow screen smart bold: 700

@mixin simply-fit-gotham-narrow() {
  $gotham-n-ss: 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', Arial, sans-serif;
}

// PROJECT NAME: Diamond Brite 
// -------------------------------------------
// link: <link rel="stylesheet" type="text/css" href="//cloud.typography.com/6443252/621482/css/fonts.css" />
// -------------------------------------------
// fonts & weights: 
// archer semibold: 700
// archer semibold italic: 700
// --
// gotham extra light: 200
// gotham book: 400
// --
// gotham narrow screen smart extra light: 200
// gotham narrow screen smart light: 300
// gotham narrow screen smart book: 400
// gotham narrow screen smart book italic: 400
// gotham narrow screen smart bold: 700
// gotham narrow screen smart bold italic: 700
// gotham narrow screen smart black: 800

@mixin diamond-brite() {
  $archer-sb: 'Archer 7r', 'Archer A', 'Archer B', Rockwell, "Courier Bold", Courier, Georgia, Times, "Times New Roman", serif;
  $archer-sbi: 'Archer 7i', 'Archer A', 'Archer B', Rockwell, "Courier Bold", Courier, Georgia, Times, "Times New Roman", serif;
  $gotham-xl: 'Gotham 2r', 'Gotham A', 'Gotham B', "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  $gotham-bk: 'Gotham 4r', 'Gotham A', 'Gotham B', "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  $gotham-n-ss-xl: 'Gotham Narrow SSm 2r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-l: 'Gotham Narrow SSm 3r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
  $gotham-n-ss-bk: 'Gotham Narrow SSm 4r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-bki: 'Gotham Narrow SSm 4i', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-b: 'Gotham Narrow SSm 7r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-bi: 'Gotham Narrow SSm 7i', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-bl: 'Gotham Narrow SSm 8r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

// PROJECT NAME: sei bella family
// -------------------------------------------
// link: <link rel="stylesheet" type="text/css" href="//cloud.typography.com/6443252/634622/css/fonts.css" />
// -------------------------------------------
// fonts & weights: 
// didot 06 medium italic: 500
// didot 06 bold italic: 700
// --
// gotham narrow screen smart extra light: 200
// gotham narrow screen smart extra light italic: 200
// gotham narrow screen smart light: 300
// gotham narrow screen smart light italic: 300
// gotham narrow screen smart book: 400
// gotham narrow screen smart book italic: 400
// gotham narrow screen smart medium: 500
// gotham narrow screen smart bold: 700

@mixin sei-bella-family() {
  $didot-06-mi: 'Didot 06 5i', 'Didot 06 A', 'Didot 06 B', "Didot LT STD", "Hoefler Text", Garamond, "Times New Roman", serif;
  $didot-06-bi: 'Didot 06 5i', 'Didot 06 A', 'Didot 06 B', "Didot LT STD", "Hoefler Text", Garamond, "Times New Roman", serif;
  $gotham-n-ss-xl: 'Gotham Narrow SSm 2r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-xli: 'Gotham Narrow SSm 2i', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-l: 'Gotham Narrow SSm 3r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-li: 'Gotham Narrow SSm 3i', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;  
  $gotham-n-ss-bk: 'Gotham Narrow SSm 4r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-bki: 'Gotham Narrow SSm 4i', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-m: 'Gotham Narrow SSm 5r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-b: 'Gotham Narrow SSm 7r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

// PROJECT NAME: sun shades family  !!!!!!!!!!! not ie 8 friendly !!!!!!!!!!!
// -------------------------------------------
// link: <link rel="stylesheet" type="text/css" href="//cloud.typography.com/6443252/804422/css/fonts.css" /> 
// ------------------------------------------- 
// fonts & weights: 
// archer screen smart light: 400
// archer screen smart light italic: 400
// archer screen smart medium: 600
// --
// gotham light: 300
// gotham bold: 700
// --
// gotham condensed extra light: 200
// gotham condensed book: 400
// gotham condensed medium: 500
// gotham condensed medium italic: 500
// --
// gotham narrow screen smart extra light: 200
// gotham narrow screen smart light: 300
// gotham narrow screen smart light italic: 300
// gotham narrow screen smart medium: 500
// gotham narrow screen smart bold: 700
// gotham narrow screen smart bold italic: 700
// gotham narrow screen smart black: 800

@mixin sun-shades-family() {
  $gotham-n-ss-xl: 'Gotham Narrow SSm 2r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-l: 'Gotham Narrow SSm 3r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-li: 'Gotham Narrow SSm 3i', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;  
  $gotham-n-ss-m: 'Gotham Narrow SSm 5r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-b: 'Gotham Narrow SSm 7r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-bi: 'Gotham Narrow SSm 7i', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-bl: 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

// PROJECT NAME: gotham narrow dot info
// -------------------------------------------
// link: <link rel="stylesheet" type="text/css" href="//cloud.typography.com/6443252/680506/css/fonts.css" />
// -------------------------------------------
// fonts & weights: 
// gotham narrow light: 300
// gotham narrow book: 400
// gotham narrow medium: 500
// --
// gotham narrow screen smart light: 300
// gotham narrow screen smart light italic: 300
// gotham narrow screen smart book: 400
// gotham narrow screen smart medium: 500
// gotham narrow screen smart bold: 700

@mixin gotham-narrow-dot-info() {
  $gotham-n-l: 'Gotham Narrow 3r', 'Gotham Narrow A', 'Gotham Narrow B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-bk: 'Gotham Narrow 4r', 'Gotham Narrow A', 'Gotham Narrow B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-m:  'Gotham Narrow 5r', 'Gotham Narrow A', 'Gotham Narrow B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-l: 'Gotham Narrow SSm 3r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-li: 'Gotham Narrow SSm 3i', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-bk: 'Gotham Narrow SSm 4r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-m: 'Gotham Narrow SSm 5r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss-b: 'Gotham Narrow SSm 7r', 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

// PROJECT NAME: cyclone-gotham-condensed-gotham-narrow  !!!!!!!!!!!!! not ie8 friendly !!!!!!!!!!!!!!
// -------------------------------------------
// link: <link rel="stylesheet" type="text/css" href="//cloud.typography.com/6443252/761568/css/fonts.css" /> 
// ------------------------------------------- 
// fonts & weights: 
// cyclone: 400
// --
// gotham condensed medium: 500
// --
// gotham narrow light: 300
// gotham narrow book: 400
// gotham narrow medium: 500
// --
// gotham narrow screen smart extra light: 200
// gotham narrow screen smart extra light italic: 200
// gotham narrow screen smart light: 300
// gotham narrow screen smart light italic: 300
// gotham narrow screen smart book: 400
// gotham narrow screen smart medium: 500
// gotham narrow screen smart bold: 700

@mixin cyclone-gotham-condensed-gotham-narrow() {
  $cyclone: 'Cyclone A', 'Cyclone B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
  $gotham-c: 'Gotham Cond A', 'Gotham Cond B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n: 'Gotham Narrow A', 'Gotham Narrow B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss: 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

// PROJECT NAME: gotham narrow gotham condensed  !!!!!!!!!!!!! not ie8 friendly !!!!!!!!!!!!!!
// -------------------------------------------
// link: <link rel="stylesheet" type="text/css" href="//cloud.typography.com/6443252/612768/css/fonts.css" />
// ------------------------------------------- 
// fonts & weights: 
// gotham condensed light: 300
// gotham condensed book: 400
// gotham condensed medium: 500
// gotham condensed bold: 600
// --
// gotham narrow extra light: 200 
// gotham narrow extra light italic: 200
// gotham narrow light: 300
// gotham narrow light italic: 300
// gotham narrow book: 400
// gotham narrow book italic: 400
// gotham narrow bold: 700
// gotham narrow ultra: 900
// --
// gotham narrow screen smart extra light: 200
// gotham narrow screen smart extra light italic: 200
// gotham narrow screen smart light: 300
// gotham narrow screen smart light italic: 300
// gotham narrow screen smart book: 400
// gotham narrow screen smart book italic: 400
// gotham narrow screen smart medium: 500
// gotham narrow screen smart bold: 700

@mixin gotham-narrow-gotham-condensed() {
  $gotham-c: 'Gotham Cond A', 'Gotham Cond B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n: 'Gotham Narrow A', 'Gotham Narrow B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  $gotham-n-ss: 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

// PROJECT NAME: Archer-GothamNarrow-Gotham !!!!!!!!!!!!! not ie8 friendly !!!!!!!!!!!!!!
// -------------------------------------------
// link: <link rel="stylesheet" type="text/css" href="//cloud.typography.com/6443252/655968/css/fonts.css" /> 
// ------------------------------------------- 
// fonts & weights: 
// archer light italic: 400
// archer book italic: 500
// archer medium italic: 600
// archer semibold italic: 700
// --
// gotham extra light: 200
// gotham light: 300
// gotham book: 400
// gotham medium: 500
// gotham bold: 700
// --
// gotham narrow extra light: 200
// gotham narrow light: 300
// gotham narrow book: 400
// gotham narrow medium: 500
// gotham narrow bold: 700

@mixin archer-gothamnarrow-gotham() {
  $archer: 'Archer A', 'Archer B', Rockwell, "Courier Bold", Courier, Georgia, Times, "Times New Roman", serif;
  $gotham: 'Gotham A', 'Gotham B', "Century Gothic", CenturyGothic, AppleGothic, sans-serif; 
  $gotham-n: 'Gotham Narrow A', 'Gotham Narrow B', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
} 

// PROJECT NAME: about !!!!!!!!!!!!! not ie8 friendly !!!!!!!!!!!!!!
// -------------------------------------------
// link: <link rel="stylesheet" type="text/css" href="//cloud.typography.com/6443252/647448/css/fonts.css" /> 
// ------------------------------------------- 
// fonts & weights: 
// gotham narrow extra light: 200
// gotham narrow bold: 700
// --
// gotham narrow screen smart extra light: 200
// gotham narrow screen smart extra light italic: 200
// gotham narrow screen smart book: 400
// gotham narrow screen smart book italic: 400
// gotham narrow screen smart medium: 500
// gotham narrow screen smart medium italic: 500
// gotham narrow screen smart bold: 700
// gotham narrow screen smart bold italic: 700
// --
// $gotham-n
// $gotham-n-ss

// button mixins

%button-base {    
  color: #fff;
  display: inline-block;  
  font-family: $gotham-n-ss;
  text-decoration: none;
  text-transform: uppercase;
  user-select: none;
}

@mixin wm-button($color:'green', $button-size:'standard') {
  @if $color == green {
    background: $pm-green-2;
    background: linear-gradient($pm-green-2, $pm-green-1);    

    &:hover {
      background: $pm-green-2;
      text-decoration: none;
    }
  }
  @if $color == blue {
    background: $pm-blue-1;
    background: linear-gradient($pm-blue-1, $ah-blue-1);    

    &:hover {
      background: $pm-blue-1;
      text-decoration: none;
    } 
  }
  @if $color == gray {
    background: $sd-gray-3;
    background: linear-gradient($sd-gray-3, $sd-gray-2);

    &:hover {
      background: $sd-gray-3;
      background: linear-gradient($sd-gray-3, $sd-gray-2);
      text-decoration: none;
    }
  }
  @if $button-size == 'small' {    
    border-radius: 3px;
    font-size: 12px;
    height: 30px;
    line-height: 30px;
    padding: 0 15px;
  } @else {
    border-radius: 5px;
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
  }
  @extend %button-base;
}



//============================//
//============================//
// helpful classes
//============================//
//============================//

// clearfix //
.cf:before,
.cf:after {
  content: ' ';
  display: table;
}

.cf:after {
  clear: both;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
